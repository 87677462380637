/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function startCounter(){
            $('.counter').each(function (index) {
                var size = $(this).text().split(".")[1] ? $(this).text().split(".")[1].length : 0;
                $(this).prop('Counter',0).animate({
                    Counter: $(this).text()
                }, {
                    duration: 3000,
                    easing: 'linear',
                    step: function (now) {
                        $(this).text(parseFloat(now).toFixed(size));
                    }
                });
            });
        }
        startCounter();

        //For BA Ads Simple Template
        //Remove not needed margin in footer is the cookies Alert is closed
        UIkit.util.on('.cookies', 'hide', function(){
                $('.footer-ba-ads-simple').css({
                    marginBottom: '0'
                });
        });



        //For BA Ads Simple Template
        //Consultation form styling


            $('.uikit-name > label , .uikit-email > label, .uikit-phone > label').addClass("absolute-label");
            $('.uikit-message > .ginput_container > textarea').hide();


            $( '.uikit-name .ginput_container input' ).focus(function() {
                $(this).addClass("field-focus");
                $('.uikit-name label').addClass("yellow-label relative-label");
            });
            $( '.uikit-name .ginput_container input' ).focusout(function() {
                if( $(this).val() === '' ) {
                    $('.uikit-name label').removeClass("yellow-label relative-label");
                    $(this).removeClass("field-type");
                }else{
                    $('.uikit-name > label').removeClass("yellow-label");
                }
            });
            $( '.uikit-name .ginput_container input' ).keypress(function() {
                $(this).addClass("field-type");
                $('.uikit-name label').removeClass("yellow-label");
             });


             $( '.uikit-email > .ginput_container > input' ).focus(function() {
                 $('.uikit-email > label').addClass("yellow-label relative-label");
             });
             $( '.uikit-email > .ginput_container > input' ).focusout(function() {
                 if( $(this).val() === '' ) {
                     $('.uikit-email > label').removeClass("yellow-label relative-label");
                     $(this).removeClass("field-type");
                 }else{
                     $('.uikit-email > label').removeClass("yellow-label");
                 }
             });
             $( '.uikit-email > .ginput_container > input' ).keypress(function() {
                 $(this).addClass("field-type");
                 $('.uikit-email > label').removeClass("yellow-label");
              });


              $( '.uikit-phone > .ginput_container > input' ).focus(function() {
                  $(this).addClass("field-focus");
                  $('.uikit-phone > label').addClass("yellow-label relative-label");
              });
              $( '.uikit-phone > .ginput_container > input' ).focusout(function() {
                  if( $(this).val() === '(___) ___-____' ) {
                      $('.uikit-phone > label').removeClass("yellow-label relative-label");
                      $(this).removeClass("field-type");
                  }else{
                      $('.uikit-phone > label').removeClass("yellow-label");
                  }
              });
              $( '.uikit-phone > .ginput_container > input' ).keypress(function() {
                  $(this).addClass("field-type");
                  $('.uikit-phone > label').removeClass("yellow-label");
               });



              $( '.uikit-message > label' ).click(function(event) {
                  event.preventDefault();
                 $('.uikit-message > .ginput_container > textarea').show(500);
               });
               $( '.uikit-message > .ginput_container > textarea' ).keypress(function() {
                   $(this).addClass("field-type-textarea");
                   $('.uikit-message > label').addClass("no-yellow-label");
                });



                $(document).bind('gform_post_render', function(){
                    if( $( '.uikit-name > .ginput_container > input' ).val() !== '' ) {
                        $('.uikit-name > .ginput_container > input').addClass("field-type");
                        $('.uikit-name > label').addClass("absolute-label relative-label");
                    }
                    if( $( '.uikit-email > .ginput_container > input' ).val() !== '' ) {
                        $('.uikit-email > .ginput_container > input').addClass("field-type");
                        $('.uikit-email > label').addClass("absolute-label relative-label");
                    }
                    if( $( '.uikit-phone > .ginput_container > input' ).val() !== '' ) {
                        $('.uikit-phone > .ginput_container > input').addClass("field-type");
                        $('.uikit-phone > label').addClass("absolute-label relative-label");
                    }

                });



                $(window).scroll(function(e){
                  var $el = $('.fixedElement');
                  var isPositionFixed = ($el.css('position') === 'fixed');
                  if ($(this).scrollTop() > 275 && !isPositionFixed){
                    $('.fixedElement').css({'position': 'fixed', 'margin-top': '-325px'});
                  }
                  if ($(this).scrollTop() < 275 && isPositionFixed)
                  {
                    $('.fixedElement').css({'position': 'absolute', 'margin-top': '-50px'});
                  }
                  if ($(this).scrollTop() > 1075 && !isPositionFixed){
                    $('.fixedElement').css({'position': 'fixed', 'margin-top': '-325px'});
                  }
                });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
          var navigation = document.getElementById("menu-primary_navigation");
          var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
          navigation.insertAdjacentHTML( 'beforeend', li_element );


          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'terms': {
          init: function() {
              // JavaScript to be fired on the about us page
              var navigation = document.getElementById("menu-primary_navigation");
              var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
              navigation.insertAdjacentHTML( 'beforeend', li_element );
          }
    },
    'privacy_policy': {
          init: function() {
              // JavaScript to be fired on the about us page
              var navigation = document.getElementById("menu-primary_navigation");
              var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
              navigation.insertAdjacentHTML( 'beforeend', li_element );
          }
    },
    'reshift_revolutionizes_facebook_and_google_advertising_multi_location_businesses': {
          init: function() {
              // JavaScript to be fired on the about us page
              var navigation = document.getElementById("menu-primary_navigation");
              var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
              navigation.insertAdjacentHTML( 'beforeend', li_element );
          }
      },
    'page_template_page_features': {
      init: function() {
        // JavaScript to be fired on the landing page
          var navigation = document.getElementById("menu-primary_navigation");
          var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
          navigation.insertAdjacentHTML( 'beforeend', li_element );
      }
    },
    'page_template_page_landing': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
    'page_template_page_landing_cfa_special': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },



	'page_template_page_landing_cfa_special_search': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
	'page_template_page_landing_sba_offer': {
      init: function() {
        // JavaScript to be fired on the landing page
          console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
    'page_template_page_landing_retail': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
    'page_template_page_landing_multi': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
    'page_template_page_landing_franchise': {
      init: function() {
        // JavaScript to be fired on the landing page
          //console.log('Hurray!');

          $(window).on('scroll',function(){

              if($(window).scrollTop() > 2 ){
                  $('.navbar-header-landing-small').show();
                  $('.navbar-header-landing').hide();

                  $('.navbar-land').css({"border-color": "#e7e7e7",
                      "border-width":"0 0 1px",
                      "border-style":"solid"});
              } else{
                  $('.navbar-header-landing').show();
                  $('.navbar-header-landing-small').hide();
                  $('.navbar-land').css({"border": "none"});
              }


          });

              if ($(window).width() >= 750) {
                  $( '.tbl-row' ).removeClass( "collapse" );
                  $( '.show-collapse' ).hide();
                  $( '.hide-collapse' ).show();

              }
              else {
                  $( '.hide-collapse' ).hide();
                  $( '.show-collapse' ).show();
              }


          $('button').on('tap', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });

          $('button').on('click', function(){
              if($(this).hasClass( "show-collapse" )){
                  $('.collapse').on('shown.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                  }).on('hidden.bs.collapse', function(){
                      $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                      $(this).parent().find(".show-collapse").removeClass("collapse");
                  });
              }
          });



          $('.launch-modal').on('click', function(e){
              e.preventDefault();
              $( '#' + $(this).data('modal-id') ).modal();
          });
      }
    },
    'page_template_page_pricing': {
        init: function() {
            // JavaScript to be fired on the landing page
            var navigation = document.getElementById("menu-primary_navigation");
            var li_element = '<li class="contact-form-link menu-request-a-free-demo"><a href="#footer-anchor" style="color:white;">Request a FREE DEMO</a></li>';
            navigation.insertAdjacentHTML( 'beforeend', li_element );


            if ($(window).width() >= 750) {
                $( '.tbl-row' ).removeClass( "collapse" );
                $( '.show-collapse' ).hide();
                $( '.hide-collapse' ).show();

            }
            else {
                $( '.hide-collapse' ).hide();
                $( '.show-collapse' ).show();
            }


            $('button').on('tap', function(){
                if($(this).hasClass( "show-collapse" )){
                    $('.collapse').on('shown.bs.collapse', function(){
                        $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                    }).on('hidden.bs.collapse', function(){
                        $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                        $(this).parent().find(".show-collapse").removeClass("collapse");
                    });
                }
            });

            $('button').on('click', function(){
                if($(this).hasClass( "show-collapse" )){
                    $('.collapse').on('shown.bs.collapse', function(){
                        $(this).parent().find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-up");
                    }).on('hidden.bs.collapse', function(){
                        $(this).parent().find(".fa-caret-up").removeClass("fa-caret-up").addClass("fa-caret-down");
                        $(this).parent().find(".show-collapse").removeClass("collapse");
                    });
                }
            });


        }
    }
  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
